
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {business_natures, group_types, industry_types, business_types} from "@/core/data/genericData";
import axios from "axios";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import {gst_type, gst_return_frequency} from "@/core/data/genericData";

export default defineComponent({
  name: "edit-branch-modal",
  directives: {mask},
  props: {
    data: {
        type: Object
    }
  },
  components: {
    
    },
  setup(props) {
    const type_list_data = gst_type;
    const freq_list_data = gst_return_frequency;
    const store = useStore();
    const nob_data = business_natures;
    const group_type_data = group_types;
    const industry_types_data = industry_types;
    const business_types_data = business_types;
    const roc_list_data = roc_list;
    const formRef = ref<null | HTMLFormElement>(null);
    const editBranchModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const router = useRouter();
    var formData1 = ref({})
    // const formData = ref({
    //   name: "",      
    //   business_landline : "",
    //   business_emails : "",
    //   business_mobile : "",
    //   address_line_1 : "",
    //   address_line_2 : "",
    //   pincode : "",
    //   pincode_id : "",
    //   city : "",
    //   state : "",
    //   country : "",
    //   city_id : "",
    //   state_id : "",
    //   country_id : "",
    //   gstin : "",
    //    state_tin : "",
    //   gst_type : "",
    //   gst_return_frequency : "",
    // });  
    const company_data = ref([]);
    const setFormData = async (data) => {    
      try 
      {
        var values = { "company_branch_id": data.ids}
        
        await store.dispatch(Actions.CUST_GET_EDIT_BRANCH, values).then(({ data }) => {    
          company_data.value = data[0].company_id;   
          city_value.value = data[0].city_id
          pin_value.value = data[0].pincode_id
          state_value.value = data[0].state_id
          country_value.value = data[0].country_id
          
        formData1.value = {
          id : data[0].company_branch_id,
          name : data[0].branch_name,
          title_select: data[0].branch_title_type_id,
          business_emails: data[0].business_email_json,
          business_mobile: data[0].business_mobile_json,
          business_landline: data[0].business_landline_json,
          address_line_1: data[0].address_line_1,
          address_line_2: data[0].address_line_2,
          pincode: data[0].pincode,
          pincodeid: data[0].pincode_id,
          city: data[0].city_name,
          state: data[0].state_name,
          country: 'India',
          gstin: data[0].gstin,  
          state_tin: data[0].state_tin,
          gst_type: data[0].gst_type,
          gst_return_frequency: data[0].gst_return_frequency,
          verified_yes_no: false,
          active: 1,
          };
        })
        .catch(({ response }) => {
          console.log(response);
        });
        
      } catch (e) {
        console.log(e);
      }  
    }

    onMounted( async () => {      
      await setFormData(props.data);
      await setTitledata();
      }); 

    const rules = ref({
      name: [
        {
          required: true,
          message: "Branch Name is required",
          trigger: "change",          
        },        
      ],
      business_emails: [
        {
          required: true,
          message: "Email Name is required",
          trigger: "change",
        },        
      ],
      business_mobile: [
        {
          required: true,
          message: "Mobile is required",
          trigger: "change",
          min: 10,
          max: 10,
        },
      ],
      address_line_1: [
        {
          required: true,
          message: "Address is required",
          trigger: "change",
        },
      ],      
      pincode: [
        {
          required: true,
          message: "Pincode is required",
          trigger: "click",
          min: 6,
          max: 6,
          pattern: /[0-9]/,
        },
      ],
      
      gstin: [
        {
          required: true,
          message: "Please enter GSTIN",
          trigger: "change",
        },
        {
          required: true,
          message: "GSTIN is required",
          trigger: "click",
          min: 15,
          max: 15,
          pattern: '^[0-9]{2}[A-Za-z]{3}[CPHFATBLJGcphfatblj]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}[0-9A-Za-z]{1}(Z|z)[0-9A-Za-z]{1}$',
        },
      ],
      gst_type: [
        {
          required: true,
          message: "Gst Type is required",
          trigger: "change",
        },
      ],
      
      gst_return_frequency: [
        {
          required: true,
          message: "GST Return frequency is required",
          trigger: "change",
        },
      ],      
    });

    const title = ref([]);
    const setTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_ADDRESS_TITLE_LIST_DATA)
          .then(({ data }) => {
            title.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const city_value = ref()
    const pin_value = ref()
    const state_value = ref()
    const country_value = ref()

    const getPincodeData = async () => {  

        loadingPin.value = true;
        const db_data = { "search_term" : formData1.value['pincode'] }

        await store.dispatch(Actions.CUST_GET_PINCODE, db_data).then(({ data }) => {

            console.log(data);

          if(data){
            formData1.value['city'] = data['city_name']
            formData1.value['state'] = data['state_name']
            formData1.value['country'] = "India"
            formData1.value['city_id'] = data['city_id']
            formData1.value['stateid'] = data['state_id']
            formData1.value['pincodeid'] = data['pincode_id']
            formData1.value['countryid'] = data['country_id']
            loadingPin.value = false;

            city_value.value = data.city_id
            pin_value.value =  data.pincode_id
            state_value.value = data.state_id
            //country_value.value = data.country_id
          }
          else{
            formData1.value['city'] = ""
            formData1.value['state'] = ""
            formData1.value['country'] = ""
            formData1.value['city_id'] = ""
            formData1.value['state_id'] = ""
            formData1.value['pincode_id'] = ""
            formData1.value['country_id'] = ""
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            
            loadingPin.value = false;

          }
        
        })
        .catch(({ response }) => {

          Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            
            loadingPin.value = false;

        });

    }

    const setBranchData = async (data)=> {

      var user = JSON.parse(JwtService.getToken());
      console.log("Data")
      
      const db_data = {
          "company_branch_id": data.id,
          "branch_name": data.name,
          "company_id": company_data.value,
          "branch_title_type_id":data.title_select,
          "business_email_json": data.business_emails,
          "business_mobile_json": data.business_mobile,
          "business_landline_json": data.business_landline,
          "address_line_1": data.address_line_1,
          "address_line_2": data.address_line_2,
          "pincode_id": pin_value.value,
          "city_id": city_value.value,
          "state_id": state_value.value,
          "country_id": 101,
          "gstin": data.gstin,  
          "state_tin": data.state_tin,
          "gst_type": data.gst_type,
          "gst_return_frequency": data.gst_return_frequency,
          "verified_yes_no": false,
          "active": 1,
          "user_id": user.user_id,
        }
        console.log(db_data)
        
        await store.dispatch(Actions.CUST_EDIT_BRANCH, db_data).then(({ data }) => {
                    
          if (data.is_success){
        
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Branch has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editBranchModalRef.value);   
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }

        })
        .catch(({ response }) => {
          return false;
        });
    }

    const submit = async () => {
      console.log("insubmitttttttttttttttttt")
      console.log(formRef.value)
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {
        console.log("validateeeeeeeeeeeee")
        console.log(valid)
        if (valid) {
          await setBranchData(formData1.value);        
        } else {
          loading.value = false;
          return false;
        }
      });
    };
 

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      //formData,
      formData1,
      nob_data,
      rules,
      submit,
      getPincodeData,
      formRef,
      loading,
      loadingPin,
      editBranchModalRef,
      freq_list_data,
      type_list_data,
      setTitledata,
      title
    };
  },
});
