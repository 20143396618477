
import { defineComponent, onMounted, ref } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import PaymentRecords from "@/components/customers/cards/overview/PaymentRecords.vue";
import PaymentMethods from "@/components/customers/cards/overview/PaymentMethods.vue";
import CreditBalance from "@/components/customers/cards/overview/CreditBalance.vue";
import Invoices from "@/components/customers/cards/overview/Invoices.vue";

import Events from "@/components/customers/cards/events-and-logs/Events.vue";
import Logs from "@/components/customers/cards/events-and-logs/Logs.vue";

import Earnings from "@/components/customers/cards/statments/Earnings.vue";
import Statement from "@/components/customers/cards/statments/Statement.vue";
import axios from "axios";
import EditCompanyModal from "@/components/modals/forms/EditCompanyModal.vue";
import DetailsVue from "@/components/subscriptions/view/Details.vue";
import EditBranchModal from "@/components/modals/forms/EditBranchModal.vue";
// console.log(this.$route.query.test)

export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "companys-details",
  components: {
    // PaymentRecords,
    // PaymentMethods,
    // CreditBalance,
    // Invoices,
    // EditCompanyModal,
    // Events,
    // Logs,
    // Earnings,
    // Statement,
    // Dropdown3,
    // NewCardModal,
    EditBranchModal
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();

   interface ViewCompanies {
      company_branch_id,
      company_id,
      address_line_1,
      address_line_2,
      pincode,
      city_name,
      state_name,
      // country_name,
      branch_name,
      business_email_json,
      business_mobile_json,
      business_landline_json,
      gstin,
      state_tin,
      gst_type,
      gst_return_frequency,    
      verified_yes_no: {
        label: string;
        color: string;
      },
      active: {
        label: string;
        color: string;
      }
    };    
    
    var resultsView = ref<ViewCompanies>();
  
    resultsView.value = {
      company_branch_id : "",
      company_id : "",
      address_line_1 : "",
      address_line_2 : "",
      pincode : "",
      city_name : "",
      state_name : "",
      // country_name : "",
      branch_name : "",
      business_email_json : "",
      business_mobile_json : "",
      business_landline_json : "",
      gstin : "",
      state_tin : "",
      gst_type : "",
      gst_return_frequency : "",
      verified_yes_no: {
        label  : "",
        color : "",
      },
      active: {
        label : "",
        color : "",
      }
    }

    const getUsers = async () => {
      try {

      var values = { "company_branch_id"  :  props.id}

      await store.dispatch(Actions.CUST_GET_EDIT_BRANCH, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data[0]['verified_yes_no']){
            status_label = "Yes";
            status_color = "success";
        }else{
            status_label = "No";
            status_color = "danger";
        }

        if (data[0]['active']){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
    
        resultsView.value = {
          company_branch_id :  data[0]['company_branch_id'],
          company_id :  data[0]['company_id'],
          address_line_1 : data[0]['address_line_1'],
          address_line_2 : data[0]['address_line_2'],
          pincode : data[0]['pincode'],
          city_name : data[0]['city_name'],
          state_name : data[0]['state_name'],          
          branch_name : data[0]['branch_name'],
          business_email_json : data[0]['business_email_json'].toString(),
          business_mobile_json : data[0]['business_mobile_json'].toString(),
          business_landline_json : data[0]['business_landline_json'].toString(),
          gstin : data[0]['gstin'],
          state_tin : data[0]['state_tin'],
          gst_type : data[0]['gst_type'],
          gst_return_frequency : data[0]['gst_return_frequency'],
          verified_yes_no: {
            label: status_label,
            color: status_color
          },
          active: {
            label: active_label,
            color: active_color
          },
        }

        
      console.log("branch DetailsVue")
      console.log(resultsView)
              

      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
    
    onMounted( async () => {
      await getUsers();
      setCurrentPageBreadcrumbs("Branch Details", ["Company"]);
    });


    return {
      resultsView,
      ids,
    };

  }
});
